import React from "react";

const Contact = () => {
  return (
    <div>
      <h2>Contacto</h2>
      <p>¿Interesado en nuestros servicios? Contáctanos.</p>
      <p>Email: ezliving@example.com</p>
    </div>
  );
};

export default Contact;
